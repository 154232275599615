import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CHAIN,
  TonConnectButton,
  TonConnectUIProvider,
  useTonConnectUI,
} from "@tonconnect/ui-react";
import Section from "../components/section";
import CollectionCreateForm from "../components/collectionCreateForm";
import NftMintForm from "../components/nftMintForm";
import TonConnectBtn from "../components/connectBtn";
import { tonMainfestUrl } from "../config";
import { useTonConnect } from "../hooks/useTonConnect";
import TonInfo from "../components/info";
import WithdrawBtn from "../components/withdrawBtn";
import MintItemBtn from "../components/mintItemBtn";
import PageLinkSection from "../components/pageLinkSection";
import Header from "../components/header";

const MainPage = () => {
  const { network } = useTonConnect();

  return (
    <div className="app mainPage">
      <div className="snftBody">
        <Header />
        <div className="sectionNavBkock">
          <div className="sectionNavInner">
            <PageLinkSection
              img={"/images/diamond.png"}
              title={"Create collection"}
              url={"/collection/create"}
            />
            {/* <PageLinkSection title={"Create NFT in collection"} url={"/collection/create"} /> */}
            <PageLinkSection
              img={"/images/tgconnect.png"}
              title={"Connect telegram account"}
              url={"/account"}
            />
            <WithdrawBtn />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
