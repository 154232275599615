import React, { useEffect, useState, Suspense, lazy } from "react";
import { Routes, Route, HashRouter, BrowserRouter } from "react-router-dom";
import "./scss/app.scss";
import "./scss/app.scss";
import { useTonConnect } from "./hooks/useTonConnect";

import CollectionCreatePage from "./pages/createCollection";
import SingleCollectionPage from "./pages/collection";
import MainPage from "./pages/mainPage";
import AccountPage from "./pages/account";


const App = () => {
  const { network } = useTonConnect();

  return (
    <BrowserRouter>
        <Routes>
          <Route path="/account" element={<AccountPage />}/>
          <Route path="/collection/create" element={<CollectionCreatePage />}/>
          <Route path="/collection/:collection" element={<SingleCollectionPage />} />
          <Route path="/item/:item" element={<SingleCollectionPage />} />
          <Route path="/" element={<MainPage />} />
        </Routes>
    </BrowserRouter>
  );
};

export default App;
